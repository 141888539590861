import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
const APP_ID = process.env.INTERCOM_APP_ID;
import posthog from 'posthog-js'
import { postHogHostUrl } from '../utilities/config';
import { postHogAPIKey } from '../utilities/config';

const QuickStartPage = lazy(() => import('./views/QuickStartPage'));
const MotorGetStartedPage = lazy(() => import('./views/MotorGetStartedPage'));
const PaymentRecollectV3 = lazy(() => import('./views/PaymentRecollectV3'));
const DealershipGetStarted = lazy(() => import('./views/DealershipGetStarted'));
const DealershipSignIn = lazy(() => import('./views/DealershipSignIn'));
const DealershipSignUp = lazy(() => import('./views/DealershipSignUp'));
const DealershipHowItWorks = lazy(() => import('./views/DealershipHowItWorks'));
const SyncPolicyPage = lazy(() => import('./views/SyncPolicyPage'));
const MotorLandingPage = lazy(() => import('./views/MotorLandingPage'));
const MotorProofOfCoverPage = lazy(() => import('./views/MotorProofOfCoverPage'));
const SalesAgentSignInPage = lazy(() => import('./views/SalesAgentSignInPage'));
const DealershipResetPassword = lazy(() => import('./views/DealershipResetPassword'));
const MotorDashboardPage = lazy(() => import('./views/MotorDashboardPage'));
const MotorVehicleDetailsPage = lazy(() => import('./views/MotorVehicleDetailsPage'));
const MotorPersonalDetailsPage = lazy(() => import('./views/MotorPersonalDetailsPage'));
const MotorQuotePage = lazy(() => import('./views/MotorQuotePage'));
const MotorPaymentDetailsPage = lazy(() => import('./views/MotorPaymentDetailsPage'));
const MotorSuccessPage = lazy(() => import('./views/MotorSuccessPage'));
const DealershipDashboard = lazy(() => import('./views/DealershipDashboard'));
const DealershipTrackLeads = lazy(() => import('./views/DealershipTrackLeads'));
const DealershipProfile = lazy(() => import('./views/DealershipProfile'));
const DealershipNewPassword = lazy(() => import('./views/DealershipNewPassword'));
const ClaimGetStartedPage = lazy(() => import('./views/ClaimGetStartedPage'));
const ClaimNewPage = lazy(() => import('./views/ClaimNewPage'));


import { Helpers } from '../utilities/helpers';
import { AppProvider } from './context/AppContext';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const BUGSNAG_KEY = process.env.BUGSNAG_API_KEY
// dynamically load stylesheets to improve initial load
// load font (Montserrat)
var cssFontMontserrat = document.createElement('link');
cssFontMontserrat.href = 'https://fonts.googleapis.com/css?family=Montserrat:ital,wght@0,400;0,500;0,600;1,700,900&display=swap';
cssFontMontserrat.rel = 'stylesheet';
cssFontMontserrat.type = 'text/css';
document.getElementsByTagName('head')[0].appendChild(cssFontMontserrat);
// load fontawesome
var cssFa = document.createElement('link');
cssFa.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
cssFa.rel = 'stylesheet';
cssFa.type = 'text/css';
document.getElementsByTagName('head')[0].appendChild(cssFa);

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (event?.errors?.[0]?.errorMessage === 'ResizeObserver loop completed with undelivered notifications.') {
      return false
    }
  }
})

// this will stop test data being sent to posthog
if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  // initialize posthog in production
  posthog.init(postHogAPIKey, { api_host: postHogHostUrl })
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const protectedRoutes = [
  {
    path: '/dealership/dashboard',
    component: DealershipDashboard,
  },
  {
    path: '/dealership/track-leads',
    component: DealershipTrackLeads,
  },
  {
    path: ['/dealership/profile', '/dealership/details'],
    component: DealershipProfile,
  },
  {
    path: '/dealership/how-it-works',
    component: DealershipHowItWorks,
  },
];

const routes = [
  {
    path: ['/dealership/sign-up', '/dealership/new'],
    component: DealershipSignUp,
  },
  {
    path: ['/dealership/sign-in', '/dealership/signin'],
    component: DealershipSignIn,
  },
  {
    path: ['/dealership/reset-password', '/dealership/new-password'],
    component: DealershipResetPassword,
  },
  {
    path: '/dealership/update-password',
    component: DealershipNewPassword,
  },
  {
    path: '/dealership/get-started',
    component: DealershipGetStarted,
  },
];

const App = () => {
  const location = useLocation();

  const handleCrossDomainMsg = (event) => {
    const PERMITTED_DOMAIN = 'https://www.pineapple.co.za';
    if (event.origin === PERMITTED_DOMAIN) {
      var msg = event.data;
      if (msg && (msg.ucid || msg.referrer_website)) {
        msg.ucid && Helpers.setInStorage('ucid', `${msg.ucid}`, true)
        msg.referrer_website && !msg.referrer_website.includes('pineapple') && Helpers.setInStorage('referrerWebsite', `${msg.referrer_website}`, true)
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', handleCrossDomainMsg);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('message', handleCrossDomainMsg)
    }
  }, [])

  React.useEffect(() => {
    // setup scroll-based listeners
    window.addEventListener('scroll', Helpers.hideMobileViewProgressIndicator)

    async function validateUser() {
      if (location.pathname.includes('/dealership')) {
        const dealershipString = await Helpers.getInStorage('dealership');
        const dealership = JSON.parse(dealershipString || '{}');
        const isProtected =
          location.pathname.includes('/dealership/sign-in') ||
          location.pathname.includes('/dealership/sign-up');
        if (!isProtected) {
          if (!dealership || !dealership._id || !dealership.uuid) {
            // alert('You must be logged in to access this page. You will now be redirected to the Sign-In page.');
            <Redirect to='/dealership/sign-in' />;
          }
        }
      }
    }

    validateUser();

    return () => {
      window.removeEventListener('scroll', Helpers.hideMobileViewProgressIndicator)
    }
  }, []);

  return (
    <ErrorBoundary>
        <IntercomProvider appId={APP_ID} initializeDelay={5000} autoBoot>
          <AppProvider>
            <Router>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    exact
                    path="/quick-start"
                    component={QuickStartPage}
                  />
                  <Route
                    exact
                    path="/sync-policy"
                    component={SyncPolicyPage}
                  />
                  <Route
                    exact
                    path='/car-insurance'
                    component={MotorLandingPage}
                  />
                  <Route
                    exact
                    path='/'
                    component={MotorGetStartedPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/get-started'
                    component={MotorGetStartedPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/dashboard'
                    component={MotorDashboardPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/vehicle-details'
                    component={MotorVehicleDetailsPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/personal-details'
                    component={MotorPersonalDetailsPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/my-quote'
                    component={MotorQuotePage}
                  />
                  <Route
                    exact
                    path='/payments/recollect'
                    component={PaymentRecollectV3}
                  />
                  <Route
                    exact
                    path='/car-insurance/payment-details'
                    component={MotorPaymentDetailsPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/success'
                    component={MotorSuccessPage}
                  />
                  <Route
                    exact
                    path='/car-insurance/proof-of-cover'
                    component={MotorProofOfCoverPage}
                  />
                  <Route
                    exact
                    path="/agent/sign-in"
                    component={SalesAgentSignInPage}
                  />
                  <Route
                    exact
                    path="/claims/get-started"
                    component={ClaimGetStartedPage}
                  />
                  <Route
                    exact
                    path="/claims/new"
                    component={ClaimNewPage}
                  />
                  {routes.map((routes, index) => (
                    <Route
                      key={index}
                      exact
                      path={routes.path}
                      component={routes.component}
                    />
                  ))}
                  {protectedRoutes.map((routes, index) => (
                    <Route
                      key={index}
                      exact
                      path={routes.path}
                      component={routes.component}
                    />
                  ))}
                  {/* End of new dealership platorm */}
                  <Route
                    exact
                    path='/car-insurance/proof-of-cover'
                    component={MotorProofOfCoverPage}
                  />
                </Switch>
              </Suspense>
            </Router>
          </AppProvider>
        </IntercomProvider>
    </ErrorBoundary>
  );
};

export default App;

