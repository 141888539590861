import { SecureService } from './secureService';
import { Config } from '../utilities/config';
import { Helpers } from '../utilities/helpers';

const { querystring } = Helpers;

class _Service extends SecureService {
  // create a callback request
  requestCallback = async (userId, params) => {
    const response = await fetch(
      Config.services.dealership.requestCallback.replace(':user_id', userId),
      {
        method: 'POST',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );

    return response.json();
  };

  // retrieve dealership by uuid
  retrieveDealership = async (duuid) => {
    const response = await fetch(
      Config.services.dealership.retrieve.replace(':duuid', duuid),
      {
        method: 'GET',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
      }
    );

    return response.json();
  };

  // create dealership
  createDealership = async (params) => {
    const response = await fetch(Config.services.dealership.createDealership, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });

    return response.json();
  };

  // create dealership (new platform)
  createDealership = async (params) => {
    const response = await fetch(Config.services.dealership.createDealership, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });

    return response.json();
  };

  // update dealership
  updateDealership = async (params) => {
    const response = await fetch(Config.services.dealership.update, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });

    return response.json();
  };

  // auth dealership by email and uuid and password
  authDealership = async (params) => {
    const response = await fetch(Config.services.dealership.auth, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });

    return response.json();
  };

  // send password reset email
  sendPasswordResetEmail = async (params) => {
    const response = await fetch(
      Config.services.dealership.sendPasswordResetEmail,
      {
        method: 'POST',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json();
  };

  // change password
  changePassword = async (params) => {
    const response = await fetch(Config.services.dealership.changePassword, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });
    return response.json();
  };

  // create dealership lead
  createDealershipLead = async (params) => {
    const response = await fetch(Config.services.dealership.createLead, {
      method: 'POST',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
      body: JSON.stringify(params),
    });
    return response.json();
  };

  getDealershipChartData = async (dealershipId) => {
    const response = await fetch(
      Config.services.dealership.chartData.replace(':id', dealershipId),
      {
        method: 'GET',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
      }
    );

    return response.json();
  };

  getDealershipProfile = async (dealershipId) => {
    const response = await fetch(
      Config.services.dealership.profile.replace(':id', dealershipId),
      {
        method: 'GET',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
      }
    );

    return response.json();
  };

  updateDealershipProfile = async (dealershipId, params) => {
    const response = await fetch(
      Config.services.dealership.profile.replace(':id', dealershipId),
      {
        method: 'PUT',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json();
  };

  updateDealershipBankingDetails = async (dealershipId, params) => {
    const response = await fetch(
      Config.services.dealership.bankingDetails.replace(':id', dealershipId),
      {
        method: 'PUT',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json();
  };

  updateDealershipDetails = async (dealershipId, params) => {
    const response = await fetch(
      Config.services.dealership.details.replace(':id', dealershipId),
      {
        method: 'PUT',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json();
  };

  updateDealershipMarketingDetails = async (dealershipId, params) => {
    const response = await fetch(
      Config.services.dealership.key.replace(':id', dealershipId),
      {
        method: 'PUT',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json();
  };

  getLeadStates = async () => {
    const response = await fetch(Config.services.dealership.getLeadStates, {
      method: 'GET',
      headers: {
        ...this.defaultHeaders,
        Authorization: this.generateAuthHeader(),
      },
    });

    return response.json();
  };

  getLeads = async (uuid) => {
    const response = await fetch(
      Config.services.dealership.getLeads.replace(':uuid', uuid),
      {
        method: 'GET',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
      }
    );

    return response.json();
  };

  updateLeadEmailNotification = async (uuid, checked) => {
    const response = await fetch(
      Config.services.dealership.leadEmailNotification.replace(':uuid', uuid),
      {
        method: 'PUT',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify({ checked }),
      }
    );

    return response.json();
  };

  sendQueryEmail = async (params) => {
    const response = await fetch(
      Config.services.dealership.sendQueryEmail,
      {
         method: 'POST',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json()
  };

  syncLeads = async (params) => {
    const response = await fetch(
      Config.services.dealership.sync,
      {
         method: 'POST',
        headers: {
          ...this.defaultHeaders,
          Authorization: this.generateAuthHeader(),
        },
        body: JSON.stringify(params),
      }
    );
    return response.json()
  };
}

const DealershipService = new _Service();
export { DealershipService };
