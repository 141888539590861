import { Config } from '../utilities/config'
import sha256 from 'crypto-js/sha256'

export class SecureService {
  defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    credentials: 'include'
  }

  generateAuthHeader = _ => {
    let a = Config.authHeader
    let ts = Math.round(new Date().getTime() / 1000)
    let toHash = `${a.key}:${ts}:${a.secret}`
    let hash = sha256(toHash).toString().toUpperCase()
    let header = `${a.scheme} key=${a.key} timestamp=${ts} signature=${hash}`
    return header
  }

}
