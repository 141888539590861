import React from 'react';
import { DealershipService } from '../../services/dealershipService';
import { Helpers } from '../../utilities/helpers';

export const AppContext = React.createContext('App');

export const AppProvider = ({ children }) => {
  const [state, setState] = React.useState({
    global: {
      dealership: {
        uuid: '', // cant change
        name: '',
        first_name: '', // cant change
        last_name: '', // cant change
        contact_person: '',
        contact_person_id_number: '',
        contact_number: '',
        email: '',
        sole_proprietor_id_passport_number: '',
        company_structure: '',
        registration_number: '',
        location: '',
        dp_name_and_surname: '',
        has_vat_number: '',
        vat_number: '',
        is_incentivisable: false,
        is_lead_contactable: false,
        lead_email_notification: false,
        banking_details: {
          bank: '',
          account_type: '',
          account_number: '',
          branch_name: '', // can't change
          branch_code: '', // can't change
        },
        id_number: '', // excluded; duplicate
        is_part_of_group: false,
        // group_name: '', // can't change
        has_vat_number: false,
      },
      processPercentage: 0,
      leads: [],
    },
    leads: {
      update: (lead) => {
        const contextParams = { ...state.global };
        contextParams.leads.push(lead);

        updateGlobal({ ...contextParams });
      },
    },
    profile: {
      calculate: () => {
        try {
          const dealershipDetails = Object.keys(state.global.dealership);

          const nonRequiredFields = [
            'uuid',
            'first_name',
            'last_name',
            'id_number',
            'is_incentivisable',
            'is_lead_contactable',
            'is_part_of_group',
            'has_vat_number',
            'vat_number',
            'sole_proprietor_id_passport_number',
          ];
          const nonRequiredBankingFields = ['branch_name', 'branch_code'];

          const criteria = dealershipDetails.filter((key) => {
            return !nonRequiredFields.includes(key);
          });

          const bankingDetails = state.global.dealership['banking_details'];

          let completedFields = 0;

          criteria.map((key) => {
            if (key !== 'banking_details') {
              if (state.global.dealership[key] !== '') {
                completedFields++;
              }
            } else {
              criteria.pop()
              Object.keys(bankingDetails).map((field) => {
                if (bankingDetails[field] !== '') {
                  completedFields++;
                }
              });
            }
          });

          let profileCompletionPercentage = Math.round(
            // (completedFields /
            //   (criteria.length +
            //     Object.keys(bankingDetails).length -
            //     nonRequiredBankingFields.length)) *
            //   100
            (completedFields / criteria.length) * 100
          );

          profileCompletionPercentage =
            profileCompletionPercentage > 100
              ? 100
              : profileCompletionPercentage;

          const contextParams = { ...state.global };
          const data = Object.assign({}, contextParams, {
            processPercentage:
              completedFields === 0 ? 0 : profileCompletionPercentage,
          });

          updateGlobal({ ...data });
        } catch (error) {
          return null;
        }
      },
      updateDetails: (details) => {
        const contextParams = { ...state.global };
        const data = Object.assign({}, contextParams.dealership, details);

        updateGlobal({ dealership: data });
        state.profile.calculate();
      },
    },
    dealershipProfile: {
      get: async () => {
        try {
          const dealershipStringfied = await Helpers.getInStorage('dealership');
          const dealership = JSON.parse(dealershipStringfied);

          if (dealership) {
            const profile = await DealershipService.getDealershipProfile(
              dealership._id
            );
            const contextParams = { ...state.global };
            const data = Object.assign(
              {},
              contextParams.dealership,
              profile.data
            );

            updateGlobal({ dealership: data });
            state.profile.calculate();
          }
        } catch (err) {
          console.log('Something went wrong. Could not get user details.', err);
        }
      },
    },
    letsPartnerUp: {
      update: (val, type) => {
        const contextParams = { ...state.global };

        if (type === 'is_lead_contactable') {
          contextParams.dealership.is_lead_contactable = val;
        } else {
          contextParams.dealership.is_incentivisable = val;
        }

        updateGlobal({ dealership: contextParams.dealership });
      },
    },
  });

  React.useEffect(() => {
    state.dealershipProfile.get();
  }, []);

  const updateGlobal = (params) => {
    const contextParams = state.global;
    Object.assign(contextParams, params);
    const newState = Object.assign({}, contextParams.global, state);
    setState({ ...newState });
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
