import React from 'react'
import ReactDOM from 'react-dom'
import App from '../components/App'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PopupAlert from 'react-popup'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Router>
      <PopupAlert />
      <Route path="/" component={App} />
    </Router>,
    document.body.appendChild(document.createElement('div')),
  )
})
